import React, {useEffect, useState} from 'react';
import {MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBDatatable} from 'mdb-react-ui-kit';
import '../assets/styles/import-form.css';

export function WashSaleSummary() {
  const washSale = JSON.parse( localStorage.getItem( 'importWashSale' ) );

  const washSaleUi = [];

  if ( washSale != null ) {
    for ( let i = 0; i < washSale.positions.length; i++ ) {
      const ws = washSale.positions[i];
      washSaleUi.push(
        <div key={ws.symbol} className="d-flex mb-3 align-items-center w-auto justify-content-center">
          <MDBCard style={{width: '80%'}}>
            <MDBCardBody>
              <MDBCardTitle className='mb-4'>{ws.symbol}</MDBCardTitle>
              <TransactionsTable ws={ws} />
            </MDBCardBody>
          </MDBCard>
        </div>
      );
    }
  }

  if ( washSaleUi.length === 0 ) {
    return (
      <>
        <MDBCard style={{width: '80%'}}>
          <MDBCardBody>
            <MDBCardTitle className='mb-4'>Brak danych</MDBCardTitle>
            <MDBCardText>
              Nie znaleziono kwalifikujących się transakcji
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </>
    )
  } else {
    return (
      <>
        {washSaleUi}
      </>
    );
  }
}

function TransactionsTable({ws}) {
  const [advancedData, setAdvancedData] = useState({
    columns: [
      { label: 'Symbol', field: 'symbol', sort: false },
      { label: 'Data operacji', field: 'bookingDate', sort: true },
      { label: 'Liczba', field: 'quantity', sort: false },
      { label: 'Waluta', field: 'currency', sort: false },
      { label: 'Cena kupna', field: 'buyPrice', sort: false },
      { label: 'Kurs kupna waluty', field: 'buyExchangeRatePln', sort: false },
      { label: 'Cena aktualna', field: 'sellPrice', sort: false },
      { label: 'Kurs waluty', field: 'sellExchangeRatePln', sort: false },
      { label: 'Przychód', field: 'gain', sort: false },
      { label: 'Przychód [PLN]', field: 'gainPln', sort: false },
      { label: 'Opis', field: 'description', sort: false },
    ],
    rows: [],
  });

  useEffect(() => {
    const printNumber = function(value) {
      return value === 0 ? '0.0' : value;
    };

    const datatable = [];
    ws.transactions.forEach((entry) => {
      datatable.push({
        symbol: entry.symbol || '',
        bookingDate: entry.bookingDate || '',
        quantity: entry.quantity || '',
        currency: entry.currency || '',
        buyPrice: entry.buyPrice || '',
        buyExchangeRatePln: entry.buyExchangeRatePln || '',
        sellPrice: entry.sellPrice || '',
        sellExchangeRatePln: entry.sellExchangeRatePln || '',
        gain: entry.gain || '',
        gainPln: entry.gainPln || '',
        description: entry.description || ''
      })
    });
    setAdvancedData((prevData) => ({
      ...prevData,
      rows: datatable
    }));
  }, []);

  return (
    <MDBDatatable
      data={advancedData}
      loadingMessage='Ładowanie danych...'
      noFoundMessage='Brak wyników'
      ofText='z'
      rowsText='Wiersze na stronę'
    />
  );
}
