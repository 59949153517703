import React, {useEffect, useState} from 'react';
import {
    MDBCard, MDBCardBody, MDBCardTitle,
    MDBInput, MDBTabsItem, MDBTabsLink, MDBIcon, MDBTooltip
} from 'mdb-react-ui-kit';
import '../assets/styles/import-form.css';

export function ImportSummary() {
  const year = localStorage.getItem('importYear');
  const equityTrades = JSON.parse(localStorage.getItem('importEquityTrades'));
  const dividends = JSON.parse(localStorage.getItem('importDividends'));
  const bondTrades = JSON.parse(localStorage.getItem('importBondTrades'));
  const bondCoupons = JSON.parse(localStorage.getItem('importBondCoupons'));
  const optionTrades = JSON.parse(localStorage.getItem('importOptionTrades'));
  const otherIncome = JSON.parse(localStorage.getItem('importOtherIncome'));
  const otherFee = JSON.parse(localStorage.getItem('importOtherFee'));

  // calculate for PIT-38 trades income (fields 22, 23)
  const globalTrades = {
      incomePln: 0.0,
      costPln: 0.0
  };

  // calculate for PIT-38 cash income (fields 45, 46)
  const globalCashIncome = {
      taxDuePln: 0.0,
      deductibleTaxPln: 0.0
  };

  // calculate for PIT-ZG (field 32)
  const pitZg = {};

  const tradesCalculation = function(pitZg, global, trades) {
      Object.keys(trades).forEach((country) => {
          if (pitZg[country] != null) {
              pitZg[country] = {
                  incomePln: pitZg[country].incomePln + trades[country].incomePln,
                  costPln: pitZg[country].costPln + trades[country].costPln
              }
          } else {
              pitZg[country] = {
                  incomePln: trades[country].incomePln,
                  costPln: trades[country].costPln
              }
          }
          global.incomePln += trades[country].incomePln;
          global.costPln += trades[country].costPln;
      });
  };

    const optionsCalculation = function(pitZg, global, trades) {
        Object.keys(trades).forEach((country) => {
            if (pitZg[country] != null) {
                if (trades[country].netIncomePln > 0) {
                    pitZg[country] = {
                        incomePln: pitZg[country].incomePln + trades[country].netIncomePln,
                        costPln: pitZg[country].costPln
                    }
                }
                if (trades[country].netIncomePln < 0) {
                    pitZg[country] = {
                        incomePln: pitZg[country].incomePln,
                        costPln: pitZg[country].costPln + (-1 * trades[country].netIncomePln)
                    }
                }
            } else {
                if (trades[country].netIncomePln > 0) {
                    pitZg[country] = {
                        incomePln: trades[country].netIncomePln,
                        costPln: 0
                    }
                }
                if (trades[country].netIncomePln < 0) {
                    pitZg[country] = {
                        incomePln: 0,
                        costPln: -1 * trades[country].netIncomePln
                    }
                }
            }
            if (trades[country].netIncomePln > 0) {
                global.incomePln += trades[country].netIncomePln;
            }
            if (trades[country].netIncomePln < 0) {
                global.costPln += -1 * trades[country].netIncomePln;
            }
        });
    };

  if (equityTrades != null) {
      tradesCalculation(pitZg, globalTrades, equityTrades);
  }
  if (bondTrades != null) {
      tradesCalculation(pitZg, globalTrades, bondTrades);
  }
  if (dividends != null) {
      globalCashIncome.taxDuePln += dividends.taxDuePln;
      globalCashIncome.deductibleTaxPln += dividends.deductibleTaxPln;
  }
  if (bondCoupons != null) {
      globalCashIncome.taxDuePln += bondCoupons.taxDuePln;
      globalCashIncome.deductibleTaxPln += bondCoupons.deductibleTaxPln;
  }
  if (optionTrades != null) {
      optionsCalculation(pitZg, globalTrades, optionTrades);
  }
  if (otherFee != null) {
      globalTrades.costPln += otherFee.costPln;
  }
  if (otherIncome != null) {
      globalCashIncome.taxDuePln += otherIncome.taxDuePln;
      globalCashIncome.deductibleTaxPln += otherIncome.deductibleTaxPln;
  }

  const pitZgUi = [];
  Object.keys(pitZg).sort().forEach((country) => {
      pitZgUi.push(
          <div key={country} className="d-flex mb-3 align-items-center w-auto justify-content-center">
          <MDBCard style={{width: '80%'}}>
              <MDBCardBody>
                  <MDBCardTitle className='mb-4'>PIT-ZG / {country}</MDBCardTitle>
                  <div className='mb-4'>
                      <div className='row my-4'>
                          <div className='col-8 mt-2 pe-0'><MDBTooltip tag='span' wrapperClass='d-inline-block'
                                 title={'Dochód z obrotu papierami wartościowymi w ' + country + ' wynikający z różnicy ceny kupna i sprzedaży'}>
                              <MDBIcon fas icon="info-circle" /></MDBTooltip> Dochód (pole 32) [PLN]:</div>
                          <div className='col-2 ps-0'><MDBInput id={country + 'income'} type='number' value={(pitZg[country].incomePln - pitZg[country].costPln).toFixed(2)} readOnly={true}/></div>
                      </div>
                  </div>
              </MDBCardBody>
          </MDBCard>
          </div>
      );
  });

  return (
    <>
    <div className="d-flex mb-3 align-items-center w-auto justify-content-center">
    <MDBCard style={{width: '80%'}}>
        <MDBCardBody>
            <MDBCardTitle className='mb-4'>PIT-38 ({year})</MDBCardTitle>
            <div className='mb-4'>
                <div className='row my-4'>
                    <div className='col-8 mt-2 pe-0'><MDBTooltip tag='span' wrapperClass='d-inline-block' title='Sumaryczny przychód ze sprzedaży papierów wartościowych (np. akcji i obligacji)'>
                        <MDBIcon fas icon="info-circle" /></MDBTooltip> Inne przychody - przychód (pole 22) [PLN]:</div>
                    <div className='col-2 ps-0'><MDBInput id='incomePln' type='number' value={globalTrades.incomePln.toFixed(2)} readOnly={true}/></div>
                </div>
                <div className='row my-4'>
                    <div className='col-8 mt-2 pe-0'><MDBTooltip tag='span' wrapperClass='d-inline-block' title='Całkowity koszt zakupu papierów wartościowych (np. akcji i obligacji), powiększony o inne koszty'>
                        <MDBIcon fas icon="info-circle" /></MDBTooltip> Inne przychody - koszty (pole 23) [PLN]:</div>
                    <div className='col-2 ps-0'><MDBInput id='costPln' type='number' value={globalTrades.costPln.toFixed(2)} readOnly={true}/></div>
                </div>
                <div className='row my-4'>
                    <div className='col-8 mt-2 pe-0'><MDBTooltip tag='span' wrapperClass='d-inline-block' title='Podatek należny od dywidend i kuponów z obligacji'>
                        <MDBIcon fas icon="info-circle" /></MDBTooltip> Zryczałtowany podatek obliczony od przychodów (pole 45) [PLN]:</div>
                    <div className='col-2 ps-0'><MDBInput id='taxDuePln' type='number' value={globalCashIncome.taxDuePln.toFixed(2)} readOnly={true}/></div>
                </div>
                <div className='row my-4'>
                    <div className='col-8 mt-2 pe-0'><MDBTooltip tag='span' wrapperClass='d-inline-block' title='Podatek od dywidend i kuponów z obligacji zapłacony za granicą'>
                        <MDBIcon fas icon="info-circle" /></MDBTooltip> Podatek zapłacony za granicą (pole 46) [PLN]:</div>
                    <div className='col-2 ps-0'><MDBInput id='deductibleTaxPln' type='number' value={globalCashIncome.deductibleTaxPln.toFixed(2)} readOnly={true}/></div>
                </div>
            </div>
        </MDBCardBody>
    </MDBCard>
    </div>
    {pitZgUi}
    </>
  );
}
