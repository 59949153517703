import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText
} from 'mdb-react-ui-kit';

function TutorialPage() {
    return (
      <>
        <div className='d-flex justify-content-center my-5' >
          <MDBCard style={{width: '80%'}}>
            <MDBCardBody className='d-flex flex-column justify'>
              {/*<MDBCardTitle>Dokumentacja</MDBCardTitle>*/}
              <div className="ms-auto me-auto ratio ratio-16x9" style={{maxWidth: "60%"}}>
                <iframe
                  src="https://www.youtube.com/embed/4SRLuUULgbc"
                  title="IBKR-PIT8C Demo"
                  allowFullScreen
                ></iframe>
              </div>
              <MDBCardText className='mt-4'>
                <hr className="hr mb-5 mt-5"/>
                <h3>1. Wstęp</h3>
                Oprogramowanie IBKR-PIT8C umożliwia obliczenie podatku należnego od inwestycji w papiery wartościowe na podstawie raportu Flex Query wygenerowanego w portalu klienta Interactive Brokers.
                <p/>
                Serwis uwzględnia następujące zdarzenia podatkowe:
                <ul>
                  <li>Zakup i sprzedaż akcji na giełdach zagranicznych</li>
                  <li>Otrzymanie dywidend od spółek zagranicznych</li>
                  <li>Handel obligacjami skarbowymi i korporacyjnymi</li>
                  <li>Zysk z odsetek wypłaconych od obligacji</li>
                  <li>Koszt opłat transakcyjnych i przewalutowań</li>
                  <li>Koszt pożyczki na zakup papierów wartościowych (tzw. konto "margin")</li>
                  <li>Zysku z oprocentowania niezainwestowanych środków (<a href={"https://www.interactivebrokers.com/en/accounts/fees/pricing-interest-rates.php"}>Interest Rates</a>)</li>
                  <li>Dochodu z tytułu wypożyczania akcji (<a href={"https://www.interactivebrokers.com/en/pricing/stock-yield-enhancement-program.php"}>Stock Yield Enhancement Program</a>)</li>
                  <li>Handel opcjami na indeksy i akcje</li>
                </ul>
                <p/>
                W obecnej wersji serwis nie uwzględnia zysku i straty z:
                <ul>
                  <li>Obrotu pozostałymi instrumentami pochodnymi (np. opcje na kontrakty futures, kontrakty CFD, kontrakty futures)</li>
                </ul>
                <p/>
                Głównym celem oprogramowania jest nie tylko obliczenie wartości jakie podatnik powinien wpisać w
                konkretnych polach formularza PIT-38, ale także łatwe wyliczenie podatku w kolejnych latach.
                Serwis nie przechowuje żadnych informacji z udostepnionych raportów.

                <hr className="hr mb-5 mt-5"/>
                <h3>2. Przygotowanie raportów Flex Query</h3>
                Serwis wymaga od użytkownika wgranie dwóch raportów Flex w formacie CSV. Poniższe sekcje pokazują jak wygenerować oba raporty.

                <p className='mb-5'/>
                <h5>2.1. Raport transakcji i zdarzeń korporacyjnych</h5>
                Instrukcja przygotowania raportu:
                <ol>
                  <li>Zaloguj się do konta Interactive Brokers</li>
                  <li>Z górnego menu wybierz <i>Performance & Reports</i> <i className="fas fa-arrow-right-long"></i> <i>Flex Queries</i></li>
                  <li>Dodaj nowy raport klikając znak <i className="fas fa-plus"></i></li>
                  <li>W polu <i>Query Name</i> wpisz nazwę raportu, np. "Transakcje"</li>
                  <li>Kliknij przycisk <i>Trades</i>, a pojawi się lista atrybutów do wybrania</li>
                  <li>Zaznacz i umieść <b>kolejno</b> na liście następujące elementy:</li>
                  <ol>
                    <li><i>Trade ID</i></li>
                    <li><i>Asset Class</i></li>
                    <li><i>Symbol</i></li>
                    <li><i>Listing Exchange</i></li>
                    <li><i>ISIN</i></li>
                    <li><i>Trade Date</i></li>
                    <li><i>Quantity</i></li>
                    <li><i>Multiplier</i></li>
                    <li><i>Currency</i></li>
                    <li><i>Trade Price</i></li>
                    <li><i>IB Commission</i></li>
                    <li><i>IB Commission Currency</i></li>
                    <li><i>Description</i></li>
                  </ol>
                  <li>Zapisz zmiany korzystając z przyciska <i>Save</i></li>
                  <li>Następnie kliknij przycisk <i>Corporate Actions</i>, a pojawi się nowa lista atrybutów do wyboru</li>
                  <li>Zaznacz i umieść <b>kolejno</b> na liście następujące elementy:</li>
                  <ol>
                    <li><i>Transaction ID</i></li>
                    <li><i>Asset Class</i></li>
                    <li><i>Symbol</i></li>
                    <li><i>Listing Exchange</i></li>
                    <li><i>Date / Time</i></li>
                    <li><i>Quantity</i></li>
                    <li><i>Multiplier</i></li>
                    <li><i>Amount</i></li>
                    <li><i>Type</i></li>
                    <li><i>Description</i></li>
                  </ol>
                  <li>Zapisz zmiany korzystając z przyciska <i>Save</i></li>
                  <li>Przewiń ekran niżej i w polu <code>Format</code> wybierz CSV</li>
                  <li>Zaznacz opcję <code>Include column headers</code></li>
                  <li>Domyślny okres <code>Period</code> ustaw na <code>Year to Date</code></li>
                  <li>W polu <code>Time Format</code> wybierz wartość <code>HHmmss TimeZone</code></li>
                  <li>Następnie zapisz wprowadzone zmiany klikając <i>Continue</i> i <i>Create</i></li>
                </ol>

                Aby wygenerować raport należy:
                <ol>
                  <li>Z górnego menu wybrać <i>Performance & Reports</i> <i className="fas fa-arrow-right-long"></i> <i>Flex Queries</i></li>
                  <li>Uruchomić raport klikając <i className="fas fa-arrow-right-long"></i> przy jego nazwie</li>
                  <li>W polu <code>Period</code> wybrać opcję <code>Custom Date Range</code>, a nastepnie ustawić daty od 1 stycznia do 31 grudnia</li>
                  <li>Po kliknięciu przycisk <i>Run</i>, plik z raportem zostanie pobrany na dysk</li>
                </ol>

                Sugeruję, aby raporty zapisywać pod nazwą w formacie "Transakcje [rok].csv", dla przykłady "Transakcje 2020.csv",
                "Transakcje 2021.csv" itd. Jeśli w danym roku przenieśliśmy swoje konto z biura węgierskiego Interactive
                Brokers do Irlandii, powinniśmy wygenerować dwa raporty - po jednym dla każdego z kont. W takim wypadku,
                należy dodać numeryczny przyrostek do nazwy pliku, na przykład "Transakcje 2023 1.csv" (plik z konta
                węgierskiego), "Transakcje 2023 2.csv" (plik z konta irlandzkiego).

                <p className='mb-5'/>
                <h5>2.2. Raport przepływów pieniężnych</h5>
                Instrukcja przygotowania raportu:
                <ol>
                  <li>Zaloguj się do konta Interactive Brokers</li>
                  <li>Z górnego menu wybierz <i>Performance & Reports</i> <i className="fas fa-arrow-right-long"></i> <i>Flex Queries</i></li>
                  <li>Dodaj nowy raport klikając znak <i className="fas fa-plus"></i></li>
                  <li>W polu <i>Query Name</i> wpisz nazwę raportu, np. "Przeplywy pieniezne"</li>
                  <li>Kliknij przycisk <i>Cash Transactions</i>, a pojawi się lista atrybutów do wybrania</li>
                  <li>Zaznacz i umieść <b>kolejno</b> na liście następujące elementy:</li>
                  <ol>
                    <li><i>Transaction ID</i></li>
                    <li><i>Trade ID</i></li>
                    <li><i>Symbol</i></li>
                    <li><i>Listing Exchange</i></li>
                    <li><i>Date / Time</i></li>
                    <li><i>Multiplier</i></li>
                    <li><i>Amount</i></li>
                    <li><i>Currency</i></li>
                    <li><i>Type</i></li>
                    <li><i>Description</i></li>
                  </ol>
                  <li>Zapisz zmiany korzystając z przyciska <i>Save</i></li>
                  <li>Przewiń ekran niżej i w polu <code>Format</code> wybierz CSV</li>
                  <li>Zaznacz opcję <code>Include column headers</code></li>
                  <li>Domyślny okres <code>Period</code> ustaw na <code>Year to Date</code></li>
                  <li>W polu <code>Time Format</code> wybierz wartość <code>HHmmss TimeZone</code></li>
                  <li>Następnie zapisz wprowadzone zmiany klikając <i>Continue</i> i <i>Create</i></li>
                </ol>

                <hr className="hr mb-5 mt-5"/>
                <h3>3. Import raportów</h3>
                Aby zaimportować raporty do wyliczenia wysokości podatku za dany rok, wypełnij <a href="/import">formularz</a>. Zwróć uwagę na potencjalnie
                nierozpoznane wiersze, których nie udało się serwisowi poprawnie sklasyfikować, gdyż nie będą one uwzględnione w rozliczeniu. Wszystkie
                błędy zostaną wyświetlone na stronie.

                <hr className="hr mb-5 mt-5"/>
                <h3>4. Archiwizacja danych</h3>
                Polecam coroczną archiwizację raportów IBKR-PIT8C. Dzięki zaproponowanej poniżej strukturze w łatwy
                sposób wgramy wszystkie pliki CSV niezbędne do wyliczenia podatku w kolejnych latach. Oprogramowanie umożliwia wyeksportowanie
                wszystkich obliczeń do pliku Excel, które archiwizujemy w podkatalogach obok raportu <i>Activity Statement</i> od Interactive Brokers.
                <p/>
                <pre>
                  ├── Transakcje 2020.csv<br />
                  ├── Transakcje 2021.csv<br />
                  ├── Przelpywy pieniezne 2020.csv<br />
                  ├── Przelpywy pieniezne 2021.csv<br />
                  ├── Raport 2020<br />
                  │   ├── IBKR Activity Statement 2020.pdf   (oryginalny plik z Interactive Brokers)<br />
                  │   └── Raport 2020.xlsx                   (wyeksportowany raport w formacie PDF)<br />
                  └── Raport 2021<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;├── IBKR Activity Statement 2021.pdf<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;└── Raport 2021.xlsx
                </pre>
                <p/>
                "Activity Statement" to zbiorczy raport w formacie PDF, który można wygenerować w portalu klienta:
                <ol>
                  <li>Z górnego menu wybierz <i>Performance & Reports</i> <i className="fas fa-arrow-right-long"></i> <i>Statements</i></li>
                  <li>Uruchom raport <code>Activity</code> klikając <i className="fas fa-arrow-right-long"></i> przy jego nazwie</li>
                  <li>W polu <code>Period</code> wybrać opcję <code>Custom Date Range</code>, a następnie ustawić daty od 1 stycznia do 31 grudnia danego roku</li>
                  <li>Kliknąć przycisk <i>Download</i> przy formacie PDF</li>
                </ol>

                <div className="note note-danger mb-3">
                  Z informacji jakie uzyskałem od osób trzecich wynika, że Interactive Brokers umożliwia generowanie raportów z przeprowadzonych transakcji
                  za ostatnie 5 lat. Pozyskanie raportów za wcześniejsze lata bywa kłopotliwe i możliwe jest jedynie za pośrednictwem wsparcia klienta.
                </div>

                <hr className="hr mb-5 mt-5"/>
                <h3>5. Opcje zaawansowane</h3>
                Sekcja obejmuje różne zaawansowane zagadnienia odnośnie importu lub generowania raportu PIT.

                <p className='mb-5'/>
                <h5>5.1. Ręczne dodanie informacji o podziale akcji (tzw. "split")</h5>
                Interactive Brokers w sekcji "Corporate Actions" zazwyczaj informuje o podziale akcji będących składnikiem portfela.
                Jeśli jednak tak się nie stanie, użytkownik otrzyma błąd o zbyt małej ilości jednostek podczas sprzedaży, lub sama
                sprzedaż będzie wiązała się z dużą stratą. Zaleca się uzupełnienie pliku z transakcjami o nowy wiersz reprezentujący
                split akcji jak na przykładzie poniżej. Rekord powinien być umieszczony pod nagłowkiem Corporate Actions. Współczynnik
                splitu sczytywany jest z ostatniej kolumny zawierającej opis.
                <p/>
                <pre>
                  "TransactionID","AssetClass","Symbol","ListingExchange","Date/Time","Quantity","Multiplier","Amount","Type","Description" (nagłówej "Corporate Actions")<br />
                  "330265502","STK","GOOG","NASDAQ","20220715;202500 EDT","1.9","1","0","FS","GOOG(US02079K1079) SPLIT 20 FOR 1 (GOOG, ALPHABET INC-CL C, US02079K1079)"
                </pre>

                <p className='mb-5'/>
                <h5>5.2. Ręczne dodanie informacji o wyodrębnieniu spółki</h5>
                Interactive Brokers nie uwzględnia w raportach informacji o tzw. "spin-off" nowej spółki. Użytkownik musi sam
                dodać wiersz w pliku transakcji dla odpowiedniego roku z ceną zakupu równą zero. W przypadku braku wpisu,
                serwis zwróci błąd przy księgowaniu operacji sprzedaży, ponieważ nie będzie umiał ustalić ceny nabycia akcji.
                <p/>
                Format wpisu:
                <pre>
                  "102785228","STK","[ticker-of-new-stock]","[exchange-name]","[isin-code]","[spin-off-date]","[number-of-equities-received]","USD","0","0","USD","[description]"
                </pre>
                <p/>
                Przykład dla firmy Kydryl wyodrębnionej z IBM w 2023:
                <pre>
                  "102785228","STK","KD","NYSE","US50155Q1004","20211103","1","USD","0","0","USD","KYDRYL SPINOFF FROM IBM"
                </pre>

                <p className='mb-5'/>
                <h5>5.3. Ręczne dodanie innych kosztów</h5>
                Urząd Skarbowy z reguły akceptuje dodatkowe koszty poniesione w procesie handlu akcjami. Jeśli broker
                obciąża Nas np. kosztami dostępności do notowań natychmiastowych, możemy dodać wiersz do pliku z
                transakcjami reprezentujący poniesiony wydatek. Przykład:
                <p/>
                <pre>
                  "104250549","CFEE","CFEE","","","20220830","1","USD","0","50","USD","Opłata 50 USD za notowanie natyczmiastowe"
                </pre>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </div>
      </>
    );
}

export default TutorialPage;
