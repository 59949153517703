import React, { useState, useEffect } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBBtn,
  MDBIcon,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink, MDBFooter,
} from 'mdb-react-ui-kit';
import '../assets/styles/navbar.css';
import {BrowserRouter as Router, Link} from 'react-router-dom';
import {v4 as uuidv4} from "uuid";
import logo from '../logo.png'

export function NavbarItem({sidenavContent}) {
  const [basicOpen, setBasicOpen] = useState(true);

  const logoutUser = () => {
    localStorage.clear();
    window.location.reload(false);
    window.location = '/';
  };

  const exportReport = async () => {
    var requestId = uuidv4().toString();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic cHprOm1kYg==");
    myHeaders.append("X-ReqID", requestId);
    myHeaders.append("Content-Type", "application/json");
    var requestBody = localStorage.getItem('requestBody');
    fetch('https://ibkrpit.pl/v1/broker/ibkr/calculate-tax/export', {
      method: 'POST',
      headers: myHeaders,
      body: requestBody,
      redirect: 'follow'
    }).then(res => res.blob())
      .then(blob => {
        var file = window.URL.createObjectURL(blob);
        const year = localStorage.getItem('importYear');

        const anchorElement = document.createElement('a');
        document.body.appendChild(anchorElement);
        anchorElement.style.display = 'none';
        anchorElement.href = file;
        anchorElement.download = 'Raport ' + year + '.xlsx';
        anchorElement.click();

        // window.location.assign(file)
        window.URL.revokeObjectURL(file);
    });
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <>
    <div>
      <MDBNavbar light bgColor='light' className='flex-nowrap' style={{
        paddingLeft: windowWidth < 476 ? '0px' : basicOpen ? '240px' : '0px',
        transition: 'padding-left 0.4s ease'
      }}>
        <MDBBtn className='ms-3' onClick={() => setBasicOpen(!basicOpen)}>
          <MDBIcon fas icon='bars' />
        </MDBBtn>
        <MDBContainer className='justify-content-center'
                            style={{
                              paddingLeft: windowWidth < 476 ? '30px' : '60px',
                              transform: windowWidth < 476 ? 'scale(0.75)' : 'scale(1)'
                            }}>
          <MDBNavbarBrand href='#'>
            <img
              src={logo}
              height='35'
              alt=''
              loading='lazy'
            />
          </MDBNavbarBrand>
        </MDBContainer>
        {localStorage.getItem('token') ?
          <div className='d-flex btn btn-primary me-3' style={{padding: 'var(--mdb-sidenav-link-padding-y) var(--mdb-sidenav-link-padding-x)'}}>
              <MDBBtn onClick={logoutUser}>
                {windowWidth > 476 ? 'Wyloguj' : <i class="fas fa-right-from-bracket"></i> }
              </MDBBtn>
              
          </div>
        :
          <Link type='button' className='btn btn-primary me-3' to='/'>
                {windowWidth > 476 ? 'Zaloguj' : <i class="fas fa-right-to-bracket"></i> }
          </Link>
        }
      </MDBNavbar>
    </div>
    <div>
      <MDBSideNav mode={windowWidth < 476 ? 'over' : 'side'} open={basicOpen} contentRef={sidenavContent} absolute>
        <MDBSideNavMenu>
          <MDBSideNavItem>
            <MDBSideNavLink tag={Link} to='/tutorial' className='mt-3'>
              <MDBIcon fas icon="book" className='me-3'/> Dokumentacja</MDBSideNavLink>
          </MDBSideNavItem>
          <hr className="hr"/>
          <MDBSideNavItem>
            <MDBSideNavLink tag={Link} to='/import' className={localStorage.getItem('token') ? '' : 'no-imported-file'}>
              <MDBIcon fas icon="file-import" className='me-3'/> Import</MDBSideNavLink>
          </MDBSideNavItem>
          <hr className="hr"/>
          <MDBSideNavItem>
            <MDBSideNavLink tag={Link} to='/equity-trades' className={localStorage.getItem('importDoneToken') ? '' : 'no-imported-file'}>
              <MDBIcon fas icon="chart-line" className='me-3'/> Handel akcjami</MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink tag={Link} to='/dividends' className={localStorage.getItem('importDoneToken') ? '' : 'no-imported-file'}>
              <MDBIcon fas icon="hand-holding-usd" className='me-3'/> Dywidendy</MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink tag={Link} to='/bond-trades' className={localStorage.getItem('importDoneToken') ? '' : 'no-imported-file'}>
              <MDBIcon fas icon="file-invoice-dollar" className='me-3'/> Handel obligacjami</MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink tag={Link} to='/bond-coupons' className={localStorage.getItem('importDoneToken') ? '' : 'no-imported-file'}>
              <MDBIcon fas icon="file-invoice-dollar" className='me-3'/> Odsetki od obligacji</MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink tag={Link} to='/option-trades' className={localStorage.getItem('importDoneToken') ? '' : 'no-imported-file'}>
              <MDBIcon fas icon="comments-dollar" className='me-3'/> Handel opcjami</MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink tag={Link} to='/other-income' className={localStorage.getItem('importDoneToken') ? '' : 'no-imported-file'}>
              <MDBIcon fas icon="socks" className='me-3'/> Inne przychody</MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink tag={Link} to='/other-fees' className={localStorage.getItem('importDoneToken') ? '' : 'no-imported-file'}>
              <MDBIcon fas icon="cloud-showers-heavy" className='me-3'/> Inne koszty</MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink tag={Link} to='/wash-sale' className={localStorage.getItem('importDoneToken') ? '' : 'no-imported-file'}>
              <MDBIcon fas icon="poll" className='me-3'/> Wash sale (beta)</MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink tag={Link} to='/summary' className={localStorage.getItem('importDoneToken') ? '' : 'no-imported-file'}>
              <MDBIcon fas icon="money-check-alt" className='me-3'/> Podsumowanie</MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink className={localStorage.getItem('importDoneToken') ? '' : 'no-imported-file'} onClick={() => exportReport()}>
              <MDBIcon fas icon="download" className='me-3'/> Export</MDBSideNavLink>
          </MDBSideNavItem>
          <hr className="hr"/>
          <MDBSideNavItem>
            <MDBSideNavLink><MDBIcon fas icon="book" className='me-3'/> ibkr.pit8c@gmail.com</MDBSideNavLink>
          </MDBSideNavItem>
          </MDBSideNavMenu>
        {/*{localStorage.getItem('token') ? */}
        {/*  <div className='d-flex' style={{padding: 'var(--mdb-sidenav-link-padding-y) var(--mdb-sidenav-link-padding-x)'}}>*/}
        {/*      <MDBBtn tag={Link} to='/' className='me-2'>*/}
        {/*        <MDBIcon fas icon="user-alt" />*/}
        {/*      </MDBBtn>*/}
        {/*      <MDBBtn onClick={logoutUser}>*/}
        {/*        Wyloguj*/}
        {/*      </MDBBtn>*/}
        {/*  </div>*/}
        {/*:*/}
        {/*  <Link type='button' className='btn btn-primary ms-3 me-2' to='/' style={{padding: 'var(--mdb-sidenav-link-padding-y) var(--mdb-sidenav-link-padding-x)'}}>*/}
        {/*    Zaloguj*/}
        {/*  </Link>*/}
        {/*}*/}
      </MDBSideNav>
      <MDBFooter className='bg-secondary text-white text-md-start fixed-bottom justify-content-center' style={{paddingLeft: windowWidth < 476 ? '0px' : basicOpen ? '240px' : '0px'}}>
        <div className='p-2 text-center'>&copy; {new Date().getFullYear()} Łukasz Antoniak. Autor dołożył wszelkich starań, aby obliczenia były poprawne, natomiast nie ponosi odpowiedzialności za błędy wynikające z użycia serwisu.</div>
      </MDBFooter>
    </div>
    </>
  );
}
