import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
} from 'mdb-react-ui-kit';
import {WashSaleSummary} from "../components/washSaleSummary";

function WashSalePage() {
    return (
      <>
        <div className='d-flex flex-column my-5' >
          <WashSaleSummary />
        </div>
      </>
    );
}

export default WashSalePage;
