import React, {useEffect, useState} from 'react';
import {
  MDBInput
} from 'mdb-react-ui-kit';
import '../assets/styles/import-form.css';

export function OtherIncomeSummary() {
  const otherIncome = JSON.parse(localStorage.getItem('importOtherIncome'));
  var grossIncome = 0;
  var taxDuePln = 0;
  var withholdingTax = 0;
  var deductibleTax = 0;
  var taxSurcharge = 0;
  var netIncome = 0;
  if (otherIncome != null) {
    grossIncome = otherIncome.grossIncomePln.toFixed(2);
    taxDuePln = otherIncome.taxDuePln.toFixed(2);
    withholdingTax = otherIncome.withholdingTaxPln.toFixed(2);
    deductibleTax = otherIncome.deductibleTaxPln.toFixed(2);
    taxSurcharge = otherIncome.taxSurchargePln.toFixed(2);
    netIncome = otherIncome.netIncomePln.toFixed(2);
  }

  return (
    <div className='mb-4'>
      <div className='row my-4'>
        <div className='col-3 mt-2 pe-0'>Przychód brutto [PLN]:</div>
        <div className='col-2 ps-0'><MDBInput id='grossIncome' type='number' value={grossIncome} readOnly={true}/></div>
      </div>
      <div className='row my-4'>
        <div className='col-3 mt-2 pe-0'>Podatek należny [PLN]:</div>
        <div className='col-2 ps-0'><MDBInput id='cost' type='number' value={taxDuePln} readOnly={true}/></div>
      </div>
      <div className='row my-4'>
        <div className='col-3 mt-2 pe-0'>Podatek odprowadzony u źródła [PLN]:</div>
        <div className='col-2 ps-0'><MDBInput id='withholdingTax' type='number' value={withholdingTax} readOnly={true}/></div>
      </div>
      <div className='row my-4'>
        <div className='col-3 mt-2 pe-0'>Podatek do odliczenia [PLN]:</div>
        <div className='col-2 ps-0'><MDBInput id='deductibleTax' type='number' value={deductibleTax} readOnly={true}/></div>
      </div>
      <div className='row my-4'>
        <div className='col-3 mt-2 pe-0'>Podatek do dopłaty [PLN]:</div>
        <div className='col-2 ps-0'><MDBInput id='taxSurcharge' type='number' value={taxSurcharge} readOnly={true}/></div>
      </div>
      <div className='row my-4'>
        <div className='col-3 mt-2 pe-0'>Dochód [PLN]:</div>
        <div className='col-2 ps-0'><MDBInput style={{backgroundColor: netIncome >= 0 ? 'lightgreen' : '#FFCCCB'}} id='netIncome' type='number' value={netIncome} readOnly={true}/></div>
      </div>
    </div>
  );
}
